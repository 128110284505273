import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import CategoryList from './CategoryList'
import ProcessSections from '../utils/processSections'

const NewsGrid = styled.div`
  .title {
    h1 {
      margin-bottom: 0;
      padding-bottom: 0;
    }

    p.author {
      font-size: 66%;
    }
  }
`

const getDate = dateStr => new Date(dateStr).toLocaleString()

export default function SingleNewsItemPage({ data }) {
  const { news } = data
  let dateProps = {}

  if (news._updatedAt) {
    dateProps = {
      time: getDate(news._updatedAt),
      update: true,
    }
  } else {
    dateProps = {
      time: getDate(news._createdAt),
      update: false,
    }
  }

  const dateStr = `${dateProps.update ? 'Updated' : 'Created'} at ${
    dateProps.time
  }`

  const { author } = news.content[0]

  return (
    <>
      <NewsGrid className="content-width">
        <div className="title">
          <h1>{news.title}</h1>
          {/* <p>{news.headline}</p> */}
          <p className="author">
            <em>
              Written by {author}. {dateStr}
            </em>
          </p>
        </div>
        <ProcessSections sections={news} />
        <CategoryList categories={news.categories} baseUrl="/news" />
      </NewsGrid>
    </>
  )
}

export const query = graphql`
  query($slug: String) {
    news: sanityNews(slug: { current: { eq: $slug } }) {
      id
      title
      headline
      _createdAt
      _updatedAt
      content: newsContent {
        ... on SanityInternalNews {
          _key
          content: _rawContent(resolveReferences: { maxDepth: 25 })
          author
        }
      }
      categories {
        id
        name
        color {
          hex
        }
        invert
      }
    }
  }
`
